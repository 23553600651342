/* .hide{
  display: none;
} */

/* .MuiInput-root{
  width: 100% !important;
} */

.confirmationCode {
    display: flex;
    align-self: center;
    justify-content: left;
    margin-left: 4%;
    margin-top: -15px;
  }
  
  .confirmationCode > div:nth-child(1) h2 {
    color: #f07167;
    font-weight: unset;
    font-weight: 300;
    margin-right: 0.5em;
  }
  
  .lefImage h1 {
    color: #f07167;
    font-weight: bold;
    margin: 0.2em;
  }
  .paperModal {
    position: absolute;
    width: 60%;
    background-color: #fff;
    border-radius: 20px;
    height: 98vh;
  }
  .paperModal1 {
    position: absolute;
    width: 50%;
    background-color: #fff;
    border-radius: 20px;
    height: 25vh;
  }
  .buttonNext {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
  }
  
  .secCompany {
    color: #00afb9;
    margin: 0em;
  }
  
  .buttonNext button {
    background: #f07167;
    border-radius: 16px;
    border-radius: 8px;
    padding: 0.5em 1em;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    float: left;
  }
  
  .loaderAdj {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .captcha {
    margin: 2em 0;
    display: flex;
    justify-content: center;
  }
  
  .accordionHolder {
    width: 60%;
    margin: 0 auto;
  }
  
  .accordionbg {
    position: relative;
  }
  
  .accSummary {
    background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
    border-radius: 10px;
    padding-top: 7px;
    width: 100%;
  }
  
  .content {
    background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
    padding: 1em 0;
    border-radius: 20px;
    clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
  }
  
  .center {
    display: flex;
    justify-content: center;
  }
  .center img {
    height: 220px;
  }
  
  .rander {
    position: relative;
  }
  
  .rander img {
    position: absolute;
    height: 110px;
    left: 50%;
    transform: translate(-160%, 50%);
  }
  
  .successmsg {
    text-align: center;
    color: #00afb9;
  }
  
  .successmsg h1 {
    font-weight: 400;
  }
  
  .closeHolder {
    position: relative;
    cursor: pointer;
  }
  
  .closeHolder img {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 11;
    height: 33px;
  }
  
  .copyRight {
    color: #00afb9;
  }
  
  .adjs {
    font-size: 12px;
  }
  
  @media only screen and (max-width: 600px) {
    .paperModal {
      position: absolute;
      width: 86%;
      background-color: #fff;
      border-radius: 20px;
    }
    .center img {
      height: 130px;
    }
    .closeHolder img {
      position: absolute;
      top: 9px;
      right: 6px;
      z-index: 11;
      height: 23px;
    }
  
    .successmsg h1 {
      font-weight: 400;
      font-size: 20px;
    }
    .content {
      background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
      padding: 1em 0;
      border-radius: 20px;
      -webkit-clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 83% 0%, 100% 28%, 100% 100%, 0% 100%);
    }
  
    .rander img {
      position: absolute;
      height: 110px;
      left: 50%;
      transform: translate(-160%, 50%);
    }
  
    .adjs {
      font-size: 12px;
    }
  
    .accordionHolder {
      width: 100%;
    }
  
    .bannerRight {
      position: absolute;
      right: -32px;
      height: 78px;
      top: 54px;
    }
  
    .bannerHolder h1 {
      margin-top: 0.5em;
      font-size: 1.4em;
    }
  
    .copyRight {
      text-align: center;
    }
    .submit .btn {
      padding: 0.8em 1em;
    }
    .resetform {
      padding: 0.8em 1em;
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .accordionHolder {
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .adjs {
      font-size: 12px;
    }
  
    .adjsd {
      font-size: 12px;
    }
  }
  
  .beaconlogo {
    height: 21px;
  }
  .resetform {
    background: #12b7c1;
    border-radius: 16px;
    border-radius: 8px;
    padding: 0.8em 3em;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .appcoinfirm{
    font-size: 30px;
      padding: 10px;
  }