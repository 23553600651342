.pdfcontainerHolder {
  /* box-shadow: 3px 4px 13px lightgrey; */
  /* border-radius: 15px; */
  padding-left: 70px;
  margin-top: 0.5% !important;
  width: 75%;
  margin: 0 auto;
  /* margin-top: 2em; */
}
.successHolder {
  padding-left: 70px;
  margin-top: 15% !important;
  width: 75%;
  text-align: center;
  margin: 0 auto;
  font-size: 20px !important;
  vertical-align: middle;
}
.infoaccordionbg {
  position: relative;
  /* background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%); */
  border-radius: 15px;
  width: 100%;
  min-height: 70vh;
}

/* .infoaccSummary {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 48vh;
} */
.MuiCollapse-wrapper {
  box-shadow: 0px 4px 9px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.pdfcontainer {
  /* padding-top: 30px; */
  /* padding-left: 60px; */
  width: 95%;
  height: 95% !important;
  overflow-y: scroll;
  position: absolute;
}
.pdfcontainer:hover {
  overflow: auto;
}
.submit .btn {
  background: #00afb9;
  border-radius: 16px;
  border-radius: 8px;
  /* padding: 0.8em 6em; */
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 15%;
  /* height: 47px; */
}
button {
  font-family: "Poppins", sans-serif !important;
}
.submit {
  font-family: "Poppins", sans-serif !important;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-evenly;
}
.errormess {
  font-size: 12px;
  color: rgb(191, 38, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}
.suberrormess {
  font-size: 12px;
  color: rgb(191, 38, 0);
  margin-top: -20px;
  margin-bottom: -10px;
}
.MuiFormLabel-root.Mui-focused {
  color: black !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid black !important;
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #00afb9 !important;
}
.viewer-toolbar-left {
  display: none !important;
}
.viewer-toolbar-right {
  display: none !important;
}
.MuiFormLabel-root {
  font-family: "Poppins", sans-serif !important;
}
.date {
  /* padding-left: 15px; */
  padding-top: 20px;
}
@media only screen and (max-width: 768px) {
  .submit .btn {
    width: 45% !important;
  }
  .btn {
    width: 35% !important;
  }
  .date {
    padding-left: 15px !important;
    padding-top: 20px !important;
  }
  .acceptpadding {
    padding-bottom: 15px !important;
  }
  .pdfcontainer {
    padding-left: 10px !important;
  }
  .infoaccordionbg {
    min-height: 68vh !important;
  }
  .pdfcontainerHolder {
    width: 90% !important;
    padding-left: 0px !important;
  }
  .header {
    margin-left: 1em !important;
  }
  .scrollpadding {
    padding-left: 25px !important;
  }
  .namepadding {
    padding-left: 15px !important;
  }
  .successHolder {
    margin-top: 25% !important;
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 769px) {
  /* .namepadding {
    padding-left: 80px !important;
  } */
}
