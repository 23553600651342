.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2em;
  margin-left: 1em;
}
.headerTitle {
  font-size: 24px;
  display: flex;
  justify-content: space-evenly;
  margin-top: -2.5em;
}
.pdfheaderTitle {
  font-size: 24px;
  display: flex;
  justify-content: space-evenly;
  margin-top: -2.5em;
}
.headerlogo {
  width: 184px;
}

.headertext {
  width: 193px;
}

.lgoutbtn {
  background: #f46674;
  border: none;
  padding: 8px 10px;
  color: white;
  border-radius: 7px 7px 7px 7px;
  width: 8%;
  font-size: 12px;
  margin-right: 9em;
  outline: none;
}
.lgoutbtn:focus {
  outline: none;
}

.widgetcontainer {
  display: flex;
  margin-bottom: 20px;
  margin-top: 3em;
  margin-left: 9.5em;
}
.modalBox {
  position: fixed; /* Stay in place */
  z-index: 1111111; /* Sit on top */
  /* left: 0; */
  /* top: 0; */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  padding-top: 300px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 45%; /* Could be more or less, depending on screen size */
  border-radius: 20px !important;

  position: relative;
}

.closeIcn {
  position: absolute;
  right: 21px;
  width: 24px;
  cursor: pointer;
}

.btnAdjHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2em 0; */
}
.btnAdjHeader > button:nth-child(1) {
  margin-right: 1em;
  /* margin: 2em 0; */
}

.subBtn10 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 24px;
  font-size: 15px;
  cursor: pointer;
}

.modal-content > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoutImg {
  /* display: flex; */
  justify-content: right;
  margin-top: 12.5em;
  cursor: pointer;
}
.pdflogoutImg {
  margin-right: 150px;
  margin-top: -100px;
  justify-content: right;
  cursor: pointer;
  text-align: right !important;
  padding-bottom: 20px !important;
}
@media only screen and (max-width: 768px) {
  .lgoutbtn {
    margin-right: 1em !important;
    width: 20%;
    padding: 3px 5px;
  }
  .logoutImg {
    margin-right: -19px;
    margin-top: -140px;
  }
  .pdflogoutImg {
    text-align: right;
    margin-right: -10px;
    /* margin-left: 20px; */
    /* margin-top: -1000px; */
    justify-content: right;
    cursor: pointer;
  }
}
@media only screen and (min-width: 769px) {
  .logoutImg {
    margin-right: 69px;
    margin-top: -55px;
  }
  .modalBox {
    padding-top: 200px;
    padding-left: 300px;
    padding-right: 300px;
  }
  .widgetcontainer {
    margin-left: 9.5em !important;
  }
}

@media only screen and (max-width: 768px) {
  .widgetcontainer {
    margin-left: 3.5em !important;
  }
  .headerTitle {
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-top: -0.5em;
  }
  .pdfheaderTitle {
    font-size: 24px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0.25em;
  }
}
@media only screen and (min-width: 340px) and (max-width: 700px) {
  .closeIcn {
    position: absolute;
    right: 10px !important;
    width: 20px !important;
    top: 5px !important;
    cursor: pointer;
  }
  .headerTitle {
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-top: -0.5em;
  }
  .pdfheaderTitle {
    font-size: 24px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0.25em;
  }
  .pdflogoutImg {
    margin-right: -10px !important;
    justify-content: right;
    cursor: pointer;
    text-align: right !important;
    padding-bottom: 100px !important;
  }
}
