html {
  font-size: 16px;
  box-sizing: border-box;
}
.MuiTypography-body1
{
  font-family: 'Poppins', sans-serif !important;

}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url(../src/Poppins-Regular.ttf) format("truetype");
}
*,
*:before,
*:after {
  box-sizing: inherit;
  outline: 0;
}

body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  font-family: 'Poppins', sans-serif !important;
  font-display: swap;
}
.MuiInputBase-input
{
  font-family: 'Poppins', sans-serif !important;
}
.mainHolder {
  min-height: calc(100vh - 93.6px);
}

.MuiFormControl-root {
  width: 100% !important;
}

.btn {
  color: #fff;
  border-radius: 8px;
  background: #4bd3c5;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}

.loginGrid {
  padding: 4em 2em;
}
.MuiOutlinedInput-input {
    padding: 13.5px 14px !important;
}
a:visited {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
.loaderAdj{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
 }
 .MuiCircularProgress-colorPrimary {
  color: #4ed3c5 !important;
}
