.containerHolder1 {
  width: 85%;
  margin: 0 auto;
  margin-top: 4em;
}

.containerHolder1 h1 {
  font-weight: 600;
}
.prevQues {
  color: #4bd3c5;
  cursor: pointer;
}
.loginmobile .react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
}
.closeIcnOTP {
  position: absolute;
  right: 16px;
  width: 24px;
  cursor: pointer;
  margin-top: -35px;
}
.modal-content1 {
  background-color: #fefefe;
  margin: 8% auto;
  padding: 67px;
  border: 1px solid #888;
  width: 60%;
  border-radius: 20px;
  position: relative;
}
.accordionbg1 {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 23px;
  width: 100%;
  min-height: 28vh;
}
.errorMess {
  font-size: 13px;
  margin-top: 15px;
  color: #ec543b;
}
.successMess {
  font-size: 13px;
  margin-top: 15px;
  color: #55a055;
  text-align: center;
}
.errorMess1 {
  font-size: 13px;
  margin-top: 15px;
  color: #ec543b;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}
.accSummary1 {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 28vh;
}

/* Login Mode css */

.loginMode {
  width: 65%;
  margin: 4em auto;
  box-shadow: 3px 4px 13px lightgrey;
  text-align: center;
  padding: 4em;
  border-radius: 20px;
}
.MuiGrid-grid-xs-8 {
  max-width: 100% !important;
}
.loginSubmitHolder {
  width: 65%;
  margin: 0 auto;
}
.loginSubmit {
  width: 20% !important;
}
.loginSubmit1 {
  /* width: 100% !important; */
  font-family: "Poppins", sans-serif !important;
}
.submitFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitlgin {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}
.submitlgin .btnlgin {
  background: #4bd3c5 !important;
  border-radius: 16px;
  border-radius: 8px;
  /* padding: 0.8em 6em; */
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  padding: 0.8em 2em !important;
  /* height: 47px; */
}
.widthadj {
  width: 200px;
}

.radiogrouper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
}

/* 
otp */
.otpInput input {
  text-align: center !important;
}
.resend {
  margin-top: 2em;
  color: #4ed3c5;
}

.resend > div {
  cursor: pointer;
}
.otpText {
  margin-top: 3em;
}

.lockicon {
  height: 60px;
}

@media only screen and (max-width: 600px) {
  .loginBg {
    display: none;
  }
  .btn {
    width: 100% !important;
  }

  .containerHolder1 {
    margin-top: 0px !important;
  }
  .loginMode {
    width: 91%;
  }

  .radiogrouper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    margin-top: 2em;
  }

  .radiogrouper > div:nth-child(2) {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .loginBg {
    display: none;
  }
  .containerHolder1 {
    margin-top: 0px !important;
  }
  .btn {
    width: 100% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .loginBg {
    width: 88%;
  }
}
@media only screen and (max-width: 768px) {
  .btn {
    width: 100% !important;
  }
  .loginSubmit {
    width: 50% !important;
  }
}
